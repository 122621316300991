.dialog-bg {
    max-height: 50dvh;
    width: 80dvw;
    padding: 0.75rem;
    font-size: 14px;
    color: white;
    padding-bottom: 1.5rem;

    .main-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        padding: 0.25rem;
        border-radius: 0.5rem;
        // background: purple;
        // background: #21ACB960;
    }

    .selected-bal-bg {
        background: var(--meitms-color);
    }

    .sec-divider {
        display: flex;
        justify-content: center;
        background: var(--border-color);
        height: 1px;
        margin-bottom: 1rem;
    }

    .toggle-section {
        display: flex;
        justify-content: space-between;
    }
}

.wallet-info {
    .MuiPaper-root .MuiPaper-root {
        position: absolute;
        top: 3rem;
    }
}

.view-layer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // margin: 10px 0;
    // margin-top: 10px;

    .MuiSwitch-root {
        .MuiButtonBase-root {
            min-height: 0 !important;
            padding: 9px !important;
        }
    }

    .text {
        color: var(--border-color);
        font-size: 10px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 400;
        margin-right: 5px;
        text-transform: uppercase;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 18px;
        // margin-top: 5px;
        border: 1px solid black;
        border-radius: 34px;
        transform: scale(0.8);
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //   background-color: rebeccapurple;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: var(--border-color);
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked+.slider {
        background-color: var(--meitms-color);
    }

    input:checked+.slider:before {
        background-color: #000000;
        border: 1px solid var(--border-color);
        border-radius: 50%;
        left: -7px;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px var(--border-color);
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.withdrawdialog-bg {
    width: 80dvw;
    padding: 0.75rem;
    font-size: 14px;
    color: white;
    padding-bottom: 1.5rem;
    overflow-y: auto;
    height: 45vh;
}

.h-dialog-bg{
    width: 80dvw;
    padding: 0.75rem;
    font-size: 14px;
    color: white;
    padding-bottom: 1.5rem;
    overflow-y: auto;
}