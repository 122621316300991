.wallet-nav {

    padding-top: 0.75rem ;
    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav-link {
        display: block;
        padding: .5rem 1rem;
        color: #0d6efd;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: var(--border-color);
        color: white;
    }

    .nav-pills .nav-item:last-child .nav-link {
        border-right: 0;
        border-radius: 0px 4px 4px 0px;   
    }
    .nav-pills .nav-item:first-child .nav-link {
        border-radius: 4px 0px 0px 4px;
    }

    .nav-item{
        width: 33%;
    }
    

    .nav-pills .nav-link {
        border-radius: 0;
        background-color: var(--inputbox-color);
        color: var(--border-color);
        border-right: 1px solid var(--border-color);
        font-weight: 500;
        text-align: center;
        line-height: 1;
        cursor: pointer;
        text-decoration: none !important;

        @media screen and (max-width: 727px) {
            text-decoration: none;
        }
    }
}

.withdrawal-hl{
    animation: history_animation_keyframes 0.6s ease-in-out infinite alternate;
  }
  
  @keyframes history_animation_keyframes {
    0% {
      transform: scale(1);
    }
  
    100% {
      transform: scale(1.1);
    }
  }