.my-profile {
    color: white;

    .dialog-bg {
        max-height: 70dvh;
        width: 90dvw;
    }

    .dg-main {
        box-sizing: border-box;
        height: fit-content;
        max-height: 63vh;
        overflow-y: auto;
        overflow-x: hidden;

        .image-container{
            padding: 0.25rem;
        }

        .av-ctn {
            margin-left: auto;
            margin-right: auto;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: .25rem;
        }

        img{
            height: 64px;
        }
    }
}