@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.css';

.toast-success {
  @apply text-white flex items-center p-4 rounded-md shadow-lg;
  background-color: #2ea77a;
}

.toast-error {
  @apply text-white flex items-center p-4 rounded-md shadow-lg;
  background-color: #e16a42;
}

.toast-container {
  @apply rounded-lg;
}

/* App.css or a dedicated CSS file for custom styles */

/* Override default toast styles */
.Toastify__toast-container {
  font-family: Arial, sans-serif;
  /* Example of changing the font */
  margin-bottom: 7rem !important;
}

.Toastify__toast {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px !important;
  color: #fff;
  font-size: 14px;
  max-width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Success and error styles */
.Toastify__toast--success {
  background-color: #2ea77a !important;
  /* Success color */
}

.Toastify__toast--error {
  background-color: #e16a42 !important;
  /* Error color */
}

/* Remove icons or adjust their appearance */
.Toastify__toast-icon {
  display: none !important;
  /* Hide default icons */
}

.Toastify__close-button {
  align-self: unset !important;
}

.gold-favourite-img {
  color: gold;
  animation: history_animation_keyframes 0.6s ease-in-out infinite alternate;
}

@keyframes history_animation_keyframes {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
    color: red;
  }
}

.home-loading{
  height: 78vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-div {
  background-image:
    radial-gradient(circle at top right, var(--border-color), transparent 50%),
    radial-gradient(circle at bottom center, var(--meitms-color), transparent 52%),
    radial-gradient(circle at left center, var(--meitms-color), transparent 50%);
  background-repeat: no-repeat;
  background-size: 300px 300px, 350px 270px, 200px 200px;
  background-position: top right, bottom center, center left;
  background-color: var(--app-bg-color);
}