.home{

    .banner-section{
        margin-bottom: 1rem;
    }
    .main-section{
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        .section-title{
            font-weight: bold;
        }
        .game-name{
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .prov-name{
            font-size: 12px;
            color: gray;
        }
    }

    .home-loading{
        height: 78vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}