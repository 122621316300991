.casino {
    .main-section{
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        .section-title{
            font-weight: bold;
        }
        .game-name{
            font-size: 14px;
        }
        .prov-name{
            font-size: 12px;
            color: gray;
        }

        .prov-list{
            .game-img {
                margin-bottom: 0.5rem;
                height: 5rem;
                width: 9rem;
                border-radius: 1rem;
                padding: 1rem;
            }
        }
    }
}