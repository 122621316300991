.bet-list {
    color: white;

    .user-table{
        font-size: 14px;
    }

    .table-pagination{
        color: white;
    }

    table th, table td {
        text-align: center;
    }
}