.gameScreen {
    left: var(--tg-safe-area-inset-left, 0);
    right: var(--tg-safe-area-inset-right, 0);
    top: var(--tg-safe-area-inset-top, 0);
    bottom: var(--tg-safe-area-inset-bottom, 0);

    .contentsafearea {
        position: absolute;
        left: var(--tg-content-safe-area-inset-left, 0);
        right: var(--tg-content-safe-area-inset-right, 0);
        top: var(--tg-content-safe-area-inset-top, 0);
        bottom: var(--tg-content-safe-area-inset-bottom, 0);
    }
}