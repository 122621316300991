.customer-support {
    color: white;

    .user-table{
        font-size: 14px;
    }

    .table-pagination{
        color: white;
    }

    .search-ctn {
        height: 39px;
        width: 50%;
        padding: 0 10px;
        border-radius: 40px;
        background: var(--inputbox-color);
        border: 1px solid var(--border-color);
        backdrop-filter: blur(64px);
        box-shadow: 0px 1px 0px 0px #ffffff14 inset;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .clear-icon {
            fill: #e5e7eb;
            color: #e5e7eb;
            width: 0.8em;
            height: 0.8em;
        }

        .search-icon {
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 50%;
                height: 40%;
            }
        }

        .input-ctn {
            width: 90%;
            height: 100%;

            .input-field {
                height: 100%;
                padding: 11px 16px;
                width: 100%;
                color: white;

                outline: none;
                background-color: transparent;
            }
        }
    }
}

.cus-dialog-bg{
    width: 80dvw;
    padding: 0.75rem;
    font-size: 14px;
    color: white;
    padding-bottom: 1.5rem;
}