.provinfo {
    color: white;
    font-family: Arial, Helvetica, sans-serif;

    .game-name {
        font-size: 12px;
    }

    .search-games-ctn {
        margin: auto 4px auto auto;
        min-width: 170px;
        border-radius: 100px;
        position: relative;

        .search-autocomplete-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            background-color: var(--ion-color-primary);
            position: absolute;
            top: 11px;
            right: 12px;
            cursor: pointer;
        }

        .autocomplete-games-text {
            color: white !important;

            .MuiInputBase-input {
                color: white !important;

            }
        }

        label {
            color: var(--ion-header-link-color) !important;
            transform: translate(14px, 12px) scale(1);
        }

        .MuiOutlinedInput-root {
            border-width: 0px;
            padding-top: 4px;
            border-radius: 100px;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            border: 1px solid rgba(var(--ion-color-primary-rgb), 0.6);
            background: transparent !important;
            color: var(--filter-controls-border) !important;

            legend {
                display: none;
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }

        .MuiFormControl-marginNormal {
            margin: auto;
        }

        .MuiInputBase-root {
            height: 38px;
        }
    }
}

@media screen and (max-width: 920px) {

    .search-games-ctn {
        position: relative;

        .search-autocomplete-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            background-color: var(--ion-color-primary);
            position: absolute;
            top: 24px;
            right: 12px;
            cursor: pointer;
        }
    }
}