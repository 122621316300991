:root {
    --inputbox-color: #1C3234;
    --border-color: #21ACB9;
    --error-color: #d9534f;
    --meitms-color: #21ACB950;
    --meicnbg-color: #21ACB930;
    --text-color: #b0b0b0;
    --app-bg-color: #051314;
    --header-color: #2E4C4E;
    --filter-color: invert(53%) sepia(92%) saturate(369%) hue-rotate(137deg) brightness(89%) contrast(93%);
}